import React from "react";
import { Helmet } from "react-helmet";
import { useI18next } from "gatsby-plugin-react-i18next";
import Layout from "../../components/layout";
import * as baseStyles from "../index.module.css";
import * as styles from "./interchange.module.css";

// logos
import visaLogo from "../../images/visa.svg";
import mastercardLogo from "../../images/mastercard.svg";

export default function Interchange() {
  const { language } = useI18next();
  return (
    <Layout>
      <Helmet>
        <title>Interchange Pricing | MyEZSolutions</title>
      </Helmet>

      <div className={baseStyles.pageContent}>
        <div className={baseStyles.pageTitle}>
          <h1>Interchange Pricing</h1>
        </div>
        <div className={styles.root}>
          <a
            href={
              language === "en_US"
                ? "/visa-usa-interchange-reimbursement-fees.pdf"
                : "/visa-canada-interchange-rates-april-2021.pdf"
            }
          >
            <div className={styles.providerCard}>
              <img
                className={styles.providerLogo}
                src={visaLogo}
                alt="VISA logo"
              />
              <h4>Visa</h4>
            </div>
          </a>
          <a
            href={
              language === "en_US"
                ? "/mastercard-usa-interchange-rates-2021.pdf"
                : "/mastercard-canada-interchange-programs-august2021.pdf"
            }
          >
            <div className={styles.providerCard}>
              <img
                className={styles.providerLogo}
                src={mastercardLogo}
                alt="Mastercard logo"
              />
              <h4>Mastercard</h4>
            </div>
          </a>
        </div>
      </div>
    </Layout>
  );
}
